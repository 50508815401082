
$(function() {

    // Flash messages
    $('.flash').removeClass('hidden').addClass('animated fadeInDown');
    
    $('.app-body-container').on('click', '.flash', function() {
         $('.flash').removeClass('bounceInDown').addClass('fadeOut');
    });    

    $('.flash').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
        window.setTimeout(function() {
            $('.flash').removeClass('bounceInDown').addClass('fadeOut');
        }, 3000);
    });

    // General form behaviour
    $(document).on('keydown', 'form.next-field-on-enter input', function(event) {
        if (event.keyCode == 13) {
            event.preventDefault();
            $(':input:eq(' + ($(':input').index(this) + 1) + ')').focus();
            return false;
        }
    });

    window_resize();


    $('.depot-switcher, .filter-incoming-state select, .filter-order-type select').change(function() {
        var loc = window.location;
        var ext = '';

        if($('.depot-switcher').val() != 'all') {
            ext = '?depot=' + $('.depot-switcher').val();
        }

        if($('.filter-incoming-state').length > 0) {
            if($('.filter-incoming-state select').val() != 'all') {
                ext += ext == "" ? "?" : "&";
                ext += 'state=' + $('.filter-incoming-state select').val();
            }
        }

        if($('.filter-order-type').length > 0) {
            if($('.filter-order-type select').val() != 'all') {
                ext += ext == "" ? "?" : "&";
                ext += 'type=' + $('.filter-order-type select').val();
            }
        }

        window.location.href = loc.protocol + '//' + loc.hostname + loc.pathname + ext;
    });


    $('.stock-filter a').click(function(){
        var loc = window.location;
        var query = "";
        var filters = ['code', 'name', 'part_type'];
        var data = [];
        var existing = [];

        //get user input
        for (var i = filters.length - 1; i >= 0; i--) {
            var tmp_hdl = $(this).parent().find('[name="filter-' + filters[i] +'"]');
            if($(tmp_hdl).length > 0) {
                if((val = $(tmp_hdl).val()) != "") {
                    data.push({k : filters[i], v : val})
                }
            }
        }

        //get existing parameters
        var search_index = loc.search.indexOf("?");
        if (search_index != -1 ) {
            var url_str = loc.search.substring(search_index +1);
            var params = url_str.split('&');
            for (var i = 0; i < params.length; i++)
            {       
                var param = params[i].split('='); 
                if(jQuery.inArray(param[0], filters) == -1) {   
                    //skip pagination page
                    if(param[0] != 'page') {
                        existing.push({k : param[0], v : param[1]});
                    }
                }
            }
        }

        //build query string
        data = data.concat(existing);
        if(data.length > 0) {
            query = '?';
            for (var i = data.length - 1; i >= 0; i--) {
                query +=  data[i].k + '=' + data[i].v;
                
                if(i > 0) {
                    query += '&';
                }
            }
        }
        
        window.location.href = loc.protocol + '//' + loc.hostname + loc.pathname + query;
    });

    $('.stock-filter input').keypress(function(event){
        if(event.which == 13) { //enter
            $(this).siblings('a').trigger('click');
        }
    });

    $('a.post-button').click(function(e) {
        e.preventDefault();
        console.log($(this));
        var href = $(this).attr('href');
        var csrf = $(this).attr('data-post-csrf');

        $('<form action="'+ href +'" method="POST">' + 
            '<input type="hidden" name="_token" value="' + csrf + '">' +
            '</form>').appendTo($('body')).submit();
    });

    /**
     * Date picker
     */

    if ($(".datepicker").length) {

        $(".datepicker").each(function(i) {

            // Is there a default ?
            var datepicker_default = new Date();
            if ($(this).val().length) {
                datepicker_default = new Date($(this).val());
            }

            $(this).pikaday({
                format: "DD/MM/YYYY",
                defaultDate: datepicker_default,
                setDefaultDate: false,
                maxDate: new Date(),
                firstDay: 1,
                onSelect: function() {
                    // $('input[name="predefined-date"]').removeAttr("checked");
                }
            });

        });

        /*
        // THIS code isn't being used on the HTS portal at the moment, but I've left it here because it may be useful when we move on to the reporting
        $('input[name="predefined-date"]').change(function() {
            var val = $(this).val();
            var start;
            var finish;
            var today = new Date();
            switch (val) {
                case 'last-month':
                    start = new Date(today.getFullYear(), today.getMonth() - 1, 1);
                    finish = new Date(today.getFullYear(), today.getMonth(), 0);
                    break;
                case 'this-month':
                    start = new Date(today.getFullYear(), today.getMonth(), 1);
                    finish = new Date(today.getFullYear(), today.getMonth() + 1, 0);
                    break;
                case 'this-week':
                    var day = today.getDay() == 0 ? 6 : today.getDay() - 1
                    start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day);
                    finish = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (6 - day));
                    break;
                case 'yesterday':
                    start = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
                    finish = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1);
                    break;
                case 'today':
                    start = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                    finish = new Date(today.getFullYear(), today.getMonth(), today.getDate());
                    break;
            }
            $("#report_date1").val(moment(start).format('D MMMM YYYY'));
            $("#report_date2").val(moment(finish).format('D MMMM YYYY'));
        });

        if ($("#report_date1").val() == "") {
            $('input[value="this-month"]').click();
        }
        */
    }



    partcomplete(); 

    $('.return-depot').change(function(){
        $('.partcomplete-code').attr('data-partcomplete-depot', $(this).val());
    });


    $(document).ready(function () {
      $('.accordion-tabs-minimal').each(function(index) {
        $(this).children('li').first().children('a').addClass('is-active').next().addClass('is-open').show();
      });
      $('.accordion-tabs-minimal').on('click', 'li > a.tab-link', function(event) {
        if (!$(this).hasClass('is-active')) {
          event.preventDefault();
          var accordionTabs = $(this).closest('.accordion-tabs-minimal');
          accordionTabs.find('.is-open').removeClass('is-open').hide();

          $(this).next().toggleClass('is-open').toggle();
          accordionTabs.find('.is-active').removeClass('is-active');
          $(this).addClass('is-active');
        } else {
          event.preventDefault();
        }
      });
    });


});

function js_flash(type, message, user_options) {

    if(typeof user_options === 'undefined') {
        user_options = {};
    }

    var options = {
        sticky: false
    };

    $.extend(options, user_options);

    var additional_classes = [];

    if(options.sticky) {
        additional_classes.push('sticky');
    }

    var flash_html = '<div class="flash flash-'+type+' noclick animated fadeInDown ' + additional_classes.join(' ') + '">'+message+'</div>';
    $('body .app-body-container').append(flash_html);

    $('.flash').one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function() {
        window.setTimeout(function() {
            $('.flash').removeClass('bounceInDown').addClass('fadeOut');
        }, 3000);
    });
}

function updateFormSubAction(button) {
    var submitVal = $(button).val();
    var submitFormSubAction = $(button).parents('form').find('input[name=_subaction]');
    if (submitVal && submitFormSubAction.length > 0) {
        submitFormSubAction.val(submitVal);
    }
}

function window_resize() {
    var browser_width = $("body").width();
}

function confirm_delete() {
    return confirm("This action is cannot be reversed.\n\nAre you sure?");
}


function partcomplete() {

      $(".partcomplete-code").autocomplete({
        source: function(val, callback) {
            var handle = $(this.element);
            var company = $(handle).attr('data-partcomplete-company');
            var depot = $(handle).attr('data-partcomplete-depot');


            //if depot isnt a id and not 'all', then use it as a selector.
            if(isNaN(parseInt(depot, 10)) && depot != 'all') {
                depot = $(depot).val();
            }

            var url =  '/' + company + '/ajax/part-suggest/' + depot + '/' + val.term; 

            if(typeof $(handle).attr('data-partcomplete-mode') !== 'undefined') {
                url = url + '?mode=' + $(handle).attr('data-partcomplete-mode');
            }

            $.ajax({
                url: url,
                dataType: 'json',
                success: function(data){

                    //sometimes need to filter stock level
                    if(typeof $(handle).attr('data-partcomplete-filter') !== 'undefined') {
                        var filter = $(handle).attr('data-partcomplete-filter');
                        if(filter == 'current-stock') {
                            data = $.grep(data, function(item, i) {
                                if(typeof item.pivot === 'undefined') {
                                    return true;
                                } else {
                                    return item.pivot.current_level > 0;
                                }
                            });
                        }
                    }

                    //format data for output
                    data = $.grep(data, function(item, i){
                        var stock = typeof item.pivot === 'undefined' ? '' : ' - ' + item.pivot.current_level + ' in stock';
                        data[i].label = item.code + ' - ' + item.name +  stock ;
                        data[i].value = item.code;
                        return true;
                    });

                    callback(data);
                }
            });    
        },
        minLength: 2,
        select: function( event, ui ) {

            var hdl = "html";

            if(typeof $(this).attr('data-partcomplete-handle') !== 'undefined') {
                hdl = $(this).attr('data-partcomplete-handle');
            }

            hdl = $(this).closest(hdl);

            //can put part name somewhere
            if(typeof $(this).attr('data-partcomplete-detail-target') !== 'undefined') {
                var detail_target = $(this).attr('data-partcomplete-detail-target');
                $(detail_target, hdl).html(ui.item.name + " - <small>" + ui.item.pivot.current_level +  " in stock</small>");
            }

            //limit input to max  current stock level
            if(typeof $(this).attr('data-partcomplete-max-target') !== 'undefined') {
                var max_target = $(this).attr('data-partcomplete-max-target');
                $(max_target, hdl).attr('max', ui.item.pivot.current_level);
            }

            $(this).val(ui.item.value).trigger('change');
        },
        response : function( event, ui ) {
            if(ui.content.length == 1) {

                var hdl = "html";

                if(typeof $(this).attr('data-partcomplete-handle') !== 'undefined') {
                    hdl = $(this).attr('data-partcomplete-handle');
                }

                hdl = $(this).closest(hdl);

                //can put part name somewhere
                if(typeof $(this).attr('data-partcomplete-detail-target') !== 'undefined') {
                    var detail_target = $(this).attr('data-partcomplete-detail-target');
                    $(detail_target, hdl).html(ui.content[0].name + " - <small>" + ui.content[0].pivot.current_level +  " in stock</small>");
                }

                //limit input to max  current stock level
                if(typeof $(this).attr('data-partcomplete-max-target') !== 'undefined') {
                    var max_target = $(this).attr('data-partcomplete-max-target');
                    $(max_target, hdl).attr('max', ui.item.pivot.current_level);
                }

                $(this).val(ui.content[0].value).trigger('change');
                $(this).autocomplete("close");
            }
        }
    }); 
}

